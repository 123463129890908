:root {
  --pink-primary: #fa5faa;
  --green-primary: #8ff5c4;
}

h1 {
  font-family: "Amatic SC", cursive;
  color: var(--pink-primary);
  letter-spacing: 2px;
  font-weight: 700;
}

p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
  font-weight: 300;
}

button {
  cursor: pointer;
  border: none;
}

.title {
  font-size: 40px;
  color: var(--pink-primary);
}

.homeTitle {
  color: var(--pink-primary);
  font-size: 60px;
}

@media (max-width: 600px) {
  .homeTitle {
    font-size: 40px;
  }

  @media (max-width: 420px) {
    .homeTitle {
      font-size: 30px;
    }
  }
  .heroSection p {
    font-size: 16px;
    max-width: 270px;
  }
}

.logoContainerMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainerMain img {
  width: 30vw;
  max-width: 150px;
}

.headerNavLinks {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  width: 100%;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 400;
}

@media (max-width: 400px) {
  .headerNavLinks {
    font-size: 14px;
  }
}

.heroSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(243, 243, 243);
  padding: 30px 0px 40px;
  text-align: center;
}

.heroSection button {
  width: 370px;
  font-size: 20px;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-family: "Josefin Sans", sans-serif;
  padding-top: 15px;
}

@media (max-width: 400px) {
  .heroSection button {
    width: 280px;
    font-size: 16px;
    padding: 7px;
    padding-top: 9px;
  }
}

.heroOrderButton {
  background-color: var(--green-primary);
  font-weight: 300;
  color: black;
}

.heroMessageButton {
  background-color: var(--pink-primary);
  color: white;
  font-weight: 400;
}

.heroSection p {
  margin: 10px 0 10px;
}

/*HomeMenuSection*/

.homeMenu {
  margin-bottom: 25px;
}

.collectionContainer {
  justify-content: center;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 30vw);
  margin-top: 20px;
}

@media (max-width: 768px) {
  .collectionContainer {
    grid-template-columns: repeat(2, 45vw);
  }
}

@media (max-width: 400px) {
  .collectionContainer {
    grid-template-columns: repeat(1, 90vw);
  }
}

.collection {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border: 10px solid #fff;
}

.collection img {
  width: 100%;
  height: 100%;
  grid-column: 1;
  grid-row: 1;
}

.collection a {
  grid-column: 1;
  grid-row: 1;
  display: flex;

  text-decoration: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 20;
  cursor: pointer;
}

.collectionOverlay {
  transition: all 0.5s;
}

.collection a:hover .collectionOverlay {
  transform: translateY(0);
  transform: scale(1.1);
}

.collection:hover > img {
  transform: scale(1.1);
  opacity: 75%;
}

.collection > img {
  transition: 0.2s linear;
}

.collection:hover span .browse {
  opacity: 1;
}

.collectionOverlay {
  width: 100%;
  transform: translateY(25%);
}

.labelPreload {
  margin: 0;
  margin-bottom: 20px;
  color: black;
  background-color: rgb(255, 255, 255, 0.75);
  padding: 5px;
  font-size: 40px;
}

.browse {
  font-family: "Josefin Sans", sans-serif;
  border-radius: 5px;
  opacity: 0;
  background-color: rgb(255, 255, 255, 0.75);
  padding: 5px;
  color: black;
  padding-top: 8px;
}

/*footer*/

footer {
  position: relative;
  border-top: 1px solid rgb(221, 221, 221);
  height: 100px;
  width: 100%;
  color: rgb(108, 108, 108);
  background-color: rgb(245, 245, 245);
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.footer-links div {
  margin: 0 20%;
}

.footer-icons a {
  padding: 0 10px;
  font-size: 24px;
  letter-spacing: 0.8;
  color: black;
}

.footer-icons .fa-instagram {
  color: rgb(0, 0, 0);
}

.fa-phone {
  font-size: 20px;
}

.insta-gallery-buttons-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.insta-gallery-buttons-container button {
  margin: 10px;
  width: 50%;
  padding: 10px;
}

/*Menu Item Component*/

.menuItemImageGallery {
  display: flex;
  margin: 10px;
  justify-content: center;
}

.menuItemImageGallery img {
  margin: 5px;
  width: 40px;
  height: 40px;
}

.menuItemImageGallery:hover {
  cursor: pointer;
}

.menuItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 70px;
  margin: 50px;
}
@media (max-width: 500px) {
  .menuItemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .menuItemContainerRight {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.menuItemContainerLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: solid black 2px; */
  width: 50%;
  max-width: 500px;
  min-width: 250px;
}

.menuItemLeftButtonsContainer {
  display: flex;
  justify-content: center;
}

.menuItemLeftButtonsContainer button {
  width: 120px;
  height: 40px;
  margin: 5px;
  margin-bottom: 25px;
  background-color: var(--pink-primary);
  color: white;
  font-weight: 400;
  letter-spacing: 1.1px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.menuItemContainerRight {
  padding: 0 25px;
  min-width: 250px;
}

.menuItemContainerRight button {
  width: 100%;
  height: 40px;
  background-color: var(--green-primary);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
}

.price {
  font-weight: 400;
  margin-bottom: 25px;
}

.menuItemDescription {
  font-weight: 200;
}

.customCookiesContainer {
  margin-bottom: 80px;
}

/* ORDER PAGE*/

.contactContainer {
  text-align: left;
  margin: 30px;
}

.contactContainer p {
  margin-bottom: 50px;
}

.contactContainer a {
  text-decoration: none;
}

/* ABOUT PAGE*/
.aboutContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
  margin-bottom: 25px;
}

.aboutText {
  margin-left: 5%;
}

.aboutContent img {
  width: 100%;
}

@media (max-width: 768px) {
  .aboutContent {
    grid-template-columns: repeat(1, 95vw);
  }
  .aboutContent h1 {
    text-align: center;
  }
}

/* GALLERY */

.galleryContainer {
  position: relative;
  min-height: 100vh;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.galleryContainer .imageContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

.galleryContainer .imageContainer .image {
  height: 250px;
  width: 250px;
  border: 5px solid #fff;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); */
  overflow: hidden;
  cursor: pointer;
}

@media (max-width: 400px) {
  .galleryContainer .imageContainer .image {
    height: 150px;
    width: 150px;
  }
}

.galleryContainer .imageContainer .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.2s linear;
}

.galleryContainer .imageContainer .image:hover img {
  transform: scale(1.1);
}

.popupImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popupImage img {
  max-width: 80%;
  max-height: 80%;
  border: solid white 5px;
}

.popupImage span {
  position: absolute;
  top: 20px;
  right: 10%;
  color: #fff;
  font-size: 35px;
  font-weight: bolder;
  cursor: pointer;
}

@media (max-width: 768px) {
  .popupImage img {
    width: 95%;
  }
}
